.root {
    --icon-sprite-grid-size: 15;
}
.company-icon-24 {
    --icon-size: 24px;
    background: url('/public/img/icons/css-sprites-24.min.png');
}
.company-icon-32 {
    --icon-size: 32px;
    background: url('/public/img/icons/css-sprites-32.min.png');
}
.company-icon-48 {
    --icon-size: 48px;
    background: url('/public/img/icons/css-sprites-48.min.png');
}
.company-icon-64 {
    --icon-size: 64px;
    background: url('/public/img/icons/css-sprites-64.min.png');
}

.company-icon {
    --col: 8; /* placeholder */
    --row: 12; /* placeholder */
    --sprite-height: var(--icon-sprite-grid-size) * var(--icon-size);
    width: var(--icon-size);
    height: var(--icon-size);
    background-size: var(--sprite-height) * var(--sprite-height);
    background-repeat: no-repeat;
    background-position: calc(var(--icon-size) * calc(var(--col) * -1))
        calc(var(--icon-size) * calc(var(--row) * -1));
}

/* ----------------------------------------------------------------- */
/* https://docs.google.com/spreadsheets/d/13ZqcUS_cCj5Kc2m3ZVex3nxTIbwwoKRCMMr7MufpnPs/edit#gid=841119892 */

.company-icon-99-pay {
    --col: 0;
    --row: 0;
}
.company-icon-aarin {
    --col: 0;
    --row: 1;
}
.company-icon-abn-amro {
    --col: 1;
    --row: 0;
}
.company-icon-advanced-corretora {
    --col: 1;
    --row: 1;
}
.company-icon-afinz {
    --col: 2;
    --row: 0;
}
.company-icon-agibank {
    --col: 2;
    --row: 1;
}
.company-icon-agora-investimentos {
    --col: 0;
    --row: 2;
}
.company-icon-al5-bank {
    --col: 1;
    --row: 2;
}
.company-icon-altbank {
    --col: 2;
    --row: 2;
}
.company-icon-ame-digital {
    --col: 3;
    --row: 0;
}
.company-icon-andbank {
    --col: 2;
    --row: 3;
}
.company-icon-apple-pay {
    --col: 3;
    --row: 1;
}
.company-icon-avenue {
    --col: 3;
    --row: 2;
}
.company-icon-banco-abc {
    --col: 0;
    --row: 3;
}
.company-icon-banco-alfa {
    --col: 1;
    --row: 3;
}
.company-icon-banco-arbi {
    --col: 2;
    --row: 4;
}
.company-icon-banco-atitude {
    --col: 4;
    --row: 0;
}
.company-icon-banco-b3 {
    --col: 3;
    --row: 3;
}
.company-icon-banco-bari {
    --col: 4;
    --row: 2;
}
.company-icon-banco-bbc {
    --col: 4;
    --row: 1;
}
.company-icon-banco-bms {
    --col: 0;
    --row: 4;
}
.company-icon-banco-brp {
    --col: 1;
    --row: 4;
}
.company-icon-banco-bv {
    --col: 4;
    --row: 3;
}
.company-icon-banco-caixa-geral-brasil {
    --col: 3;
    --row: 4;
}
.company-icon-banco-cargill {
    --col: 4;
    --row: 4;
}
.company-icon-banco-caterpillar {
    --col: 1;
    --row: 5;
}
.company-icon-banco-cnh {
    --col: 5;
    --row: 4;
}
.company-icon-banco-da-amazonia {
    --col: 5;
    --row: 0;
}
.company-icon-banco-da-china {
    --col: 4;
    --row: 5;
}
.company-icon-banco-daycoval {
    --col: 5;
    --row: 1;
}
.company-icon-banco-de-la-nacion-argentina {
    --col: 3;
    --row: 5;
}
.company-icon-banco-de-la-provincia-de-buenos-aires {
    --col: 5;
    --row: 3;
}
.company-icon-banco-do-brasil {
    --col: 5;
    --row: 5;
}
.company-icon-banco-fibra {
    --col: 6;
    --row: 2;
}
.company-icon-banco-fidis {
    --col: 6;
    --row: 0;
}
.company-icon-banco-finaxis {
    --col: 6;
    --row: 1;
}
.company-icon-banco-genial {
    --col: 6;
    --row: 3;
}
.company-icon-banco-guanabara {
    --col: 2;
    --row: 5;
}
.company-icon-banco-honda {
    --col: 2;
    --row: 6;
}
.company-icon-banco-ibm {
    --col: 6;
    --row: 6;
}
.company-icon-banco-inbursa {
    --col: 5;
    --row: 2;
}
.company-icon-banco-inter {
    --col: 0;
    --row: 5;
}
.company-icon-banco-john-deere {
    --col: 6;
    --row: 5;
}
.company-icon-banco-keb-hana {
    --col: 6;
    --row: 4;
}
.company-icon-banco-luso-brasileiro {
    --col: 0;
    --row: 7;
}
.company-icon-banco-master {
    --col: 0;
    --row: 6;
}
.company-icon-banco-mercantil {
    --col: 7;
    --row: 3;
}
.company-icon-banco-mercedes-benz {
    --col: 3;
    --row: 6;
}
.company-icon-banco-moneo {
    --col: 7;
    --row: 5;
}
.company-icon-banco-original {
    --col: 1;
    --row: 6;
}
.company-icon-banco-oro {
    --col: 4;
    --row: 6;
}
.company-icon-banco-pan {
    --col: 7;
    --row: 0;
}
.company-icon-banco-paulista {
    --col: 5;
    --row: 6;
}
.company-icon-banco-pine {
    --col: 2;
    --row: 7;
}
.company-icon-banco-randon {
    --col: 7;
    --row: 6;
}
.company-icon-banco-rendimento {
    --col: 3;
    --row: 7;
}
.company-icon-banco-rnx {
    --col: 7;
    --row: 4;
}
.company-icon-banco-semear {
    --col: 7;
    --row: 2;
}
.company-icon-banco-topazio {
    --col: 5;
    --row: 7;
}
.company-icon-banco-toyota {
    --col: 7;
    --row: 1;
}
.company-icon-banco-tricury {
    --col: 8;
    --row: 1;
}
.company-icon-banco-volvo {
    --col: 8;
    --row: 3;
}
.company-icon-banco-vr {
    --col: 8;
    --row: 5;
}
.company-icon-banese {
    --col: 1;
    --row: 7;
}
.company-icon-banestes {
    --col: 7;
    --row: 7;
}
.company-icon-bank-of-america {
    --col: 9;
    --row: 1;
}
.company-icon-banpara {
    --col: 8;
    --row: 6;
}
.company-icon-banqi {
    --col: 9;
    --row: 9;
}
.company-icon-banrisul {
    --col: 8;
    --row: 4;
}
.company-icon-bbva {
    --col: 4;
    --row: 9;
}
.company-icon-bees-bank {
    --col: 8;
    --row: 2;
}
.company-icon-beeteller {
    --col: 10;
    --row: 0;
}
.company-icon-bhcb {
    --col: 7;
    --row: 9;
}
.company-icon-bmg {
    --col: 9;
    --row: 0;
}
.company-icon-bnb {
    --col: 3;
    --row: 8;
}
.company-icon-bnp-paribas {
    --col: 9;
    --row: 2;
}
.company-icon-bny-mellon {
    --col: 4;
    --row: 7;
}
.company-icon-bocom-bbm {
    --col: 6;
    --row: 7;
}
.company-icon-br-partners {
    --col: 8;
    --row: 0;
}
.company-icon-bradesco {
    --col: 2;
    --row: 8;
}
.company-icon-brb {
    --col: 1;
    --row: 8;
}
.company-icon-bs2 {
    --col: 9;
    --row: 5;
}
.company-icon-btg-pactual {
    --col: 8;
    --row: 8;
}
.company-icon-bullla {
    --col: 8;
    --row: 7;
}
.company-icon-buni {
    --col: 0;
    --row: 8;
}
.company-icon-c6 {
    --col: 7;
    --row: 8;
}
.company-icon-caixa-economica-federal {
    --col: 4;
    --row: 8;
}
.company-icon-carrefour-solucoes-financeiras {
    --col: 0;
    --row: 9;
}
.company-icon-ccb {
    --col: 9;
    --row: 8;
}
.company-icon-cetelem {
    --col: 9;
    --row: 4;
}
.company-icon-chase {
    --col: 5;
    --row: 8;
}
.company-icon-chevrolet-servicos-financeiros {
    --col: 2;
    --row: 9;
}
.company-icon-citibank {
    --col: 9;
    --row: 3;
}
.company-icon-clara {
    --col: 3;
    --row: 9;
}
.company-icon-claro-pay {
    --col: 9;
    --row: 6;
}
.company-icon-clear {
    --col: 6;
    --row: 8;
}
.company-icon-cm-capital {
    --col: 5;
    --row: 9;
}
.company-icon-consegtur {
    --col: 1;
    --row: 9;
}
.company-icon-conta-digital-ifood {
    --col: 9;
    --row: 7;
}
.company-icon-conta-simples {
    --col: 6;
    --row: 9;
}
.company-icon-cora {
    --col: 8;
    --row: 9;
}
.company-icon-crediamigo {
    --col: 10;
    --row: 1;
}
.company-icon-credifit {
    --col: 10;
    --row: 2;
}
.company-icon-credit-agricole {
    --col: 10;
    --row: 3;
}
.company-icon-credit-suisse {
    --col: 10;
    --row: 4;
}
.company-icon-creditas {
    --col: 10;
    --row: 5;
}
.company-icon-crefisa {
    --col: 10;
    --row: 6;
}
.company-icon-cresol {
    --col: 10;
    --row: 7;
}
.company-icon-daycoval {
    --col: 10;
    --row: 8;
}
.company-icon-desty {
    --col: 10;
    --row: 9;
}
.company-icon-deutsche-bank {
    --col: 0;
    --row: 10;
}
.company-icon-digimais {
    --col: 1;
    --row: 10;
}
.company-icon-digio {
    --col: 2;
    --row: 10;
}
.company-icon-dll {
    --col: 3;
    --row: 10;
}
.company-icon-fator {
    --col: 4;
    --row: 10;
}
.company-icon-frente-corretora {
    --col: 5;
    --row: 10;
}
.company-icon-galapagos-capital {
    --col: 6;
    --row: 10;
}
.company-icon-geru {
    --col: 7;
    --row: 10;
}
.company-icon-goldman-sachs {
    --col: 8;
    --row: 10;
}
.company-icon-google-pay {
    --col: 9;
    --row: 10;
}
.company-icon-guide {
    --col: 10;
    --row: 10;
}
.company-icon-haitong {
    --col: 11;
    --row: 0;
}
.company-icon-hipercard {
    --col: 11;
    --row: 1;
}
.company-icon-hotmart {
    --col: 11;
    --row: 2;
}
.company-icon-hsbc {
    --col: 11;
    --row: 3;
}
.company-icon-icbc-do-brasil {
    --col: 11;
    --row: 4;
}
.company-icon-infinitepay {
    --col: 11;
    --row: 5;
}
.company-icon-intesa-sanpaolo-brasil {
    --col: 11;
    --row: 6;
}
.company-icon-itau {
    --col: 11;
    --row: 7;
}
.company-icon-iti {
    --col: 11;
    --row: 8;
}
.company-icon-iugu {
    --col: 11;
    --row: 9;
}
.company-icon-j-safra-sarasin {
    --col: 11;
    --row: 10;
}
.company-icon-jeitto {
    --col: 0;
    --row: 11;
}
.company-icon-jp-morgan {
    --col: 1;
    --row: 11;
}
.company-icon-kdb {
    --col: 2;
    --row: 11;
}
.company-icon-komatsu {
    --col: 3;
    --row: 11;
}
.company-icon-letsbank {
    --col: 4;
    --row: 11;
}
.company-icon-losango {
    --col: 5;
    --row: 11;
}
.company-icon-magalu-pay {
    --col: 6;
    --row: 11;
}
.company-icon-mercado-pago {
    --col: 7;
    --row: 11;
}
.company-icon-mercantil {
    --col: 8;
    --row: 11;
}
.company-icon-midway {
    --col: 9;
    --row: 11;
}
.company-icon-mitsubishi-ufj-financial-group {
    --col: 10;
    --row: 11;
}
.company-icon-mizuho {
    --col: 11;
    --row: 11;
}
.company-icon-modal {
    --col: 12;
    --row: 0;
}
.company-icon-morgan-stanley {
    --col: 12;
    --row: 1;
}
.company-icon-nbc-bank {
    --col: 12;
    --row: 2;
}
.company-icon-neon {
    --col: 12;
    --row: 3;
}
.company-icon-next {
    --col: 12;
    --row: 4;
}
.company-icon-ngcash {
    --col: 12;
    --row: 5;
}
.company-icon-nomad {
    --col: 12;
    --row: 6;
}
.company-icon-nubank {
    --col: 12;
    --row: 9;
}
.company-icon-nuinvest {
    --col: 12;
    --row: 7;
}
.company-icon-omni {
    --col: 12;
    --row: 8;
}
.company-icon-orama {
    --col: 12;
    --row: 10;
}
.company-icon-ouribank {
    --col: 12;
    --row: 11;
}
.company-icon-paccar-financial {
    --col: 0;
    --row: 12;
}
.company-icon-pagbank {
    --col: 2;
    --row: 12;
}
.company-icon-pagol {
    --col: 1;
    --row: 12;
}
.company-icon-parana-banco {
    --col: 3;
    --row: 12;
}
.company-icon-paypal {
    --col: 4;
    --row: 12;
}
.company-icon-paysmart {
    --col: 5;
    --row: 12;
}
.company-icon-picpay {
    --col: 6;
    --row: 12;
}
.company-icon-pinbank {
    --col: 7;
    --row: 12;
}
.company-icon-placeholder {
    --col: 8;
    --row: 12;
}
.company-icon-players-bank {
    --col: 9;
    --row: 12;
}
.company-icon-poupex {
    --col: 10;
    --row: 12;
}
.company-icon-rabobank {
    --col: 11;
    --row: 12;
}
.company-icon-rappipay {
    --col: 12;
    --row: 12;
}
.company-icon-recarga-pay {
    --col: 13;
    --row: 0;
}
.company-icon-revolut {
    --col: 13;
    --row: 1;
}
.company-icon-rico-investimentos {
    --col: 13;
    --row: 2;
}
.company-icon-rodobens {
    --col: 13;
    --row: 3;
}
.company-icon-safra {
    --col: 13;
    --row: 4;
}
.company-icon-samsung-pay {
    --col: 13;
    --row: 5;
}
.company-icon-santander {
    --col: 13;
    --row: 6;
}
.company-icon-scania-banco {
    --col: 13;
    --row: 7;
}
.company-icon-scotiabank {
    --col: 13;
    --row: 8;
}
.company-icon-senff {
    --col: 13;
    --row: 9;
}
.company-icon-shopee {
    --col: 13;
    --row: 10;
}
.company-icon-sicoob {
    --col: 13;
    --row: 11;
}
.company-icon-sicredi {
    --col: 13;
    --row: 12;
}
.company-icon-smbc {
    --col: 0;
    --row: 13;
}
.company-icon-social-bank {
    --col: 1;
    --row: 13;
}
.company-icon-societe-generale {
    --col: 2;
    --row: 13;
}
.company-icon-sofisa-direto {
    --col: 3;
    --row: 13;
}
.company-icon-stellantis-financiamentos {
    --col: 4;
    --row: 13;
}
.company-icon-stone {
    --col: 5;
    --row: 13;
}
.company-icon-sumitomo-mitsui-banking-corporation {
    --col: 6;
    --row: 13;
}
.company-icon-superbid-pay {
    --col: 7;
    --row: 13;
}
.company-icon-superdigital {
    --col: 8;
    --row: 13;
}
.company-icon-tanger {
    --col: 9;
    --row: 13;
}
.company-icon-tip-bank {
    --col: 10;
    --row: 13;
}
.company-icon-ton {
    --col: 4;
    --row: 14;
}
.company-icon-topazio {
    --col: 11;
    --row: 13;
}
.company-icon-toro-investimentos {
    --col: 12;
    --row: 13;
}
.company-icon-tribanco {
    --col: 13;
    --row: 13;
}
.company-icon-trigg {
    --col: 14;
    --row: 0;
}
.company-icon-trinus {
    --col: 14;
    --row: 1;
}
.company-icon-trocka {
    --col: 14;
    --row: 2;
}
.company-icon-ubs {
    --col: 14;
    --row: 3;
}
.company-icon-unicred {
    --col: 14;
    --row: 4;
}
.company-icon-uzzipay {
    --col: 14;
    --row: 5;
}
.company-icon-vivo-pay {
    --col: 14;
    --row: 6;
}
.company-icon-voiter {
    --col: 14;
    --row: 7;
}
.company-icon-volkswagen-financial-services {
    --col: 14;
    --row: 8;
}
.company-icon-warren {
    --col: 14;
    --row: 9;
}
.company-icon-western-union {
    --col: 14;
    --row: 10;
}
.company-icon-will-bank {
    --col: 14;
    --row: 11;
}
.company-icon-wise {
    --col: 14;
    --row: 12;
}
.company-icon-woori-bank-brasil {
    --col: 14;
    --row: 13;
}
.company-icon-xp {
    --col: 0;
    --row: 14;
}
.company-icon-yamaha-servicos-financeiros {
    --col: 1;
    --row: 14;
}
.company-icon-z1 {
    --col: 2;
    --row: 14;
}
.company-icon-zebra {
    --col: 3;
    --row: 14;
}
