/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv11';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* JSON editor fix (for admin pages) */
.jer-editor-container {
    max-width: 1220px !important;
}

/* Tooltip */

[data-tooltip] {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

[data-tooltip]::after {
    content: attr(data-tooltip);
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 99;
    top: -7px;
    left: 100%;
    margin-left: 10px;
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
    text-wrap: nowrap;
}

[data-tooltip]:hover::after {
    visibility: visible;
}

[data-tooltip]:hover::before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
}

/* MeuID button */
#meuid-login .btn-meuid {
    width: 100%;
    font-size: 16px;
    line-height: 1.5rem;
    background-color: #4500ba;
}

/* Fellix font  */

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-SemiBold.woff2') format('woff2'),
        url('/public/fonts/Fellix-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-Bold.woff2') format('woff2'),
        url('/public/fonts/Fellix-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-Light.woff2') format('woff2'),
        url('/public/fonts/Fellix-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-Regular.woff2') format('woff2'),
        url('/public/fonts/Fellix-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-Black.woff2') format('woff2'),
        url('/public/fonts/Fellix-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-Thin.woff2') format('woff2'),
        url('/public/fonts/Fellix-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-ExtraBold.woff2') format('woff2'),
        url('/public/fonts/Fellix-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-Medium.woff2') format('woff2'),
        url('/public/fonts/Fellix-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-Black.woff2') format('woff2'),
        url('/public/fonts/Fellix-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-Thin.woff2') format('woff2'),
        url('/public/fonts/Fellix-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-Regular.woff2') format('woff2'),
        url('/public/fonts/Fellix-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-SemiBold.woff2') format('woff2'),
        url('/public/fonts/Fellix-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-Bold.woff2') format('woff2'),
        url('/public/fonts/Fellix-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-ExtraBold.woff2') format('woff2'),
        url('/public/fonts/Fellix-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-Light.woff2') format('woff2'),
        url('/public/fonts/Fellix-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fellix';
    src: url('/public/fonts/Fellix-Medium.woff2') format('woff2'),
        url('/public/fonts/Fellix-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
